import {call, put, select, takeLatest, throttle} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import Amplify, {Auth} from 'aws-amplify';
import {REHYDRATE} from "redux-persist";
import {
    authError,
    changePasswordRequest,
    changePasswordSuccess,
    confirmForgotPasswordRequest,
    confirmForgotPasswordSuccess,
    confirmPhoneRequest,
    confirmPhoneSuccess,
    confirmSignUpRequest,
    continueAsClick,
    forgotPasswordRequest,
    refreshUserProfile,
    requestConfirmationCodeSMS,
    requestConfirmationCodeSMSSuccess,
    resendConfirmationMailSuccess,
    requestSumSubToken,
    requestSumSubTokenSuccess,
    resendConfirmationMail,
    restoreDefault,
    saveProfileRequest,
    saveProfileSuccess,
    selectFingerprint,
    selectFormInputs,
    selectUser,
    setLoading,
    signInRequest,
    signInSuccess,
    signInWithAppleRequest,
    signOut,
    signUpRequest,
    signUpSuccess,
    updateFingerprintRequest,
    updateFingerprintSuccess,
    updateSingleUserField,
    updateUserApplicantIdRequest,
    updateUserProfile,
} from "../reducers/authSlice";
import {
    defaultLocation,
    resetUserPath,
    selectInitialLocation,
    selectRedirUri,
    setUserPath,
} from "../reducers/navigationSlice";
import {nextActiveStep, resetTunnel, stepSucceeded,} from "../reducers/tunnelSlice";
import awsConfig from "../aws-config";
import config from "../config";
import {dispatchErrorAccordingToStatus, getOrPostToAuthenticatedRoute} from "./helper";
import {_parseJwt, isCognitoUserValid, isUserValid} from "../HelperFunctions";
import {
    changePassword,
    forgotPassword, OdinError,
    odinSignOut,
    pushPwd,
    refreshToken,
    resetPassword,
    signInApi,
    signInWithApple,
    signUp,
    verifyMail
} from "../odin/odin";

Amplify.configure(config.isMoniteur ? awsConfig.awsConfigMoniteur : awsConfig.awsConfigDrouot );

// checks if route can act as sign up tunnel ex: /registration route can sign up user + create registration
const isTunnelingRoute = (path) => path.includes('registration');

function* initSaga() {
    const initialLocation = yield select(state=> state.router.location);
    const searchParams = new URLSearchParams(window.location.search);
    const redirUri = searchParams.get("redirUrl");
    searchParams.delete("redirUrl");
    let url = initialLocation.pathname;
    const query = searchParams.toString();
    if (query) {
        url = `${url}?${query}`;
    }
    const userPath = {
        redirUri:redirUri,
        initialLocation: url,
    };
    const refreshTok = getOdinCookie("refresh");
    const isLoggedIn = refreshTok !== "" && refreshTok !== null;
    if (!isLoggedIn) {
        yield put(push('/login'));
        yield put(setUserPath(userPath));
        return
    }
    try {
        const resp = yield call(refreshToken, refreshTok)
        setOdinCookie(resp.accessToken.val, "access", resp.accessToken.exp);
        const user = _parseJwt(resp.accessToken.val);
        if (user) {
            //very annoying for local dev so let's check env before knowing if we redirect to dd
            if (process.env.NODE_ENV === 'production' && isUserValid(user) && userPath.initialLocation === defaultLocation) {
                yield put(setLoading());
                yield put(resetUserPath());
                handleRedirect(redirUri)
                return
            } else {
                yield put(signInSuccess({user}));
                if (isUserValid(user)) {
                    yield put(push(userPath.initialLocation));
                    userPath.initialLocation = defaultLocation;
                } else {
                    if (!isTunnelingRoute(userPath.initialLocation)) {
                        yield put(push('/sign-up/confirm'))
                    } else {
                        yield put(push(userPath.initialLocation));
                        userPath.initialLocation = null;
                    }
                }
            }
        }
    } catch (e) {
        console.log("failed to refresh from function : ", {e});
        yield put(authError(e.messageCode));
    }
    yield put(setUserPath(userPath));
}

function* loadProfileSaga() {
    const user = yield select(selectUser);
    if (user) {
        if (!isUserValid(user)) {
            console.log("user is not valid");
            yield put(push('/sign-up/confirm'));
            yield put(authError(null));
            return
        }
        try {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, "/users");
            if (resp.status !== 200) {
                yield put(authError(resp.statusText))
            } else {
                const data = yield resp.json();
                yield put(updateUserProfile(data))
            }
        } catch (e) {
            console.log({e});
            yield put(authError(e.toString()));
        }
    } else {
        // it's not a realistic error but it needs to trigger an action to stop loading
        yield put(authError(null));
    }
}

function* signInSaga() {
    const data = yield select(selectFormInputs);
    const initialLocation = yield select(selectInitialLocation);
    const redirUri = yield select(selectRedirUri);
    try {
        let response = yield call(signInApi, data.email, data.password);
        setOdinCookie(response.accessToken.val, "access", response.accessToken.exp);
        setOdinCookie(response.refreshToken.val, "refresh", response.refreshToken.exp);
        const user = _parseJwt(response.accessToken.val);
        if (isUserValid(user) && initialLocation === defaultLocation) {
            yield put(setLoading());
            yield put(resetUserPath());
            handleRedirect(redirUri)
            return
        }
        yield put(signInSuccess({user}));
        if (!isUserValid(user)){
            if (!isTunnelingRoute(initialLocation)) {
                yield put(push('/sign-up/confirm'));
            } else {
                yield put(push(initialLocation));
            }
        } else {
                yield put(push(initialLocation));
                yield put(setUserPath({initialLocation: defaultLocation}))
        }
    } catch (e) {
        if (e.messageCode === "sign.in.error.password.empty") {
            try {
                let currentUser = yield call([Auth, Auth.signIn], data.email, data.password, {email: data.email});
                if (isCognitoUserValid(currentUser)) {
                    const session = currentUser.getSignInUserSession();
                    const accessToken = session.getIdToken().getJwtToken();
                    yield call(pushPwd, data.password, accessToken);
                    let response = yield call(signInApi, data.email, data.password);
                    setOdinCookie(response.accessToken.val, "access", response.accessToken.exp);
                    setOdinCookie(response.refreshToken.val, "refresh", response.refreshToken.exp);
                    const user = _parseJwt(response.accessToken.val);
                    yield put(signInSuccess({user}));
                } else {
                    yield put(authError("password.error.mismatch"));
                }
            } catch (ee) {
                if (ee instanceof OdinError) {
                    console.log(ee.messageCode);
                    yield put(authError(ee.messageCode));
                    return
                }
                console.log(ee.code);
                yield put(authError(ee.code));
            }
            return
        }
        console.log(e.messageCode);
        yield put(authError(e.messageCode));
    }
}
function* signInWithAppleSaga(action) {
    const initialLocation = yield select(selectInitialLocation);
    const redirUri = yield select(selectRedirUri);
    try {
        let response = yield call(signInWithApple, action.payload.authorization);
        setOdinCookie(response.accessToken.val, "access", response.accessToken.exp);
        setOdinCookie(response.refreshToken.val, "refresh", response.refreshToken.exp);
        const user = _parseJwt(response.accessToken.val);
        yield put(signInSuccess({user}));
        yield put(push(initialLocation));
        yield put(setUserPath({initialLocation: defaultLocation}))
        if (isUserValid(user) && initialLocation === defaultLocation) {
            yield put(setLoading());
            yield put(resetUserPath());
            handleRedirect(redirUri)
            return
        }

        if (!isUserValid(user)){
            if (!isTunnelingRoute(initialLocation)) {
                yield put(push('/sign-up/confirm'));
            } else {
                yield put(push(initialLocation));
            }
        } else {
                yield put(push(initialLocation));
                yield put(setUserPath({initialLocation: defaultLocation}))
        }
    } catch (e) {
        console.log(e);
        yield put(authError(e.messageCode));
    }
}

function* signUpSaga() {
    const data = yield select(selectFormInputs);
    const initialLocation = yield select(selectInitialLocation);
    try {
        yield call(signUp, data.email, data.password, data.captchaToken);
        yield put(resetTunnel());
        yield put(signUpSuccess());
        if (isTunnelingRoute(initialLocation)) {
            yield put(push(initialLocation));
            yield put(setUserPath({initialLocation: defaultLocation}));
        } else {
            yield put(push('/sign-up/confirm'))
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.messageCode))
    }
}

function* confirmSignUpSaga() {
    const data = yield select(selectFormInputs);
    try {
        const response = yield call(verifyMail, data.email, data.verificationCode);
        setOdinCookie(response.accessToken.val, "access", response.accessToken.exp);
        setOdinCookie(response.refreshToken.val, "refresh", response.refreshToken.exp);
        const user = _parseJwt(response.accessToken.val);
        yield put(signInSuccess({user}));
        yield put(stepSucceeded());
    } catch (e) {
        console.log({e});
        yield put(authError(e.messageCode));
    }
}

function* resendConfirmationSaga() {
    const data = yield select(selectFormInputs);
    try {
        yield call(signUp, data.email, data.password, data.captchaToken);
        yield put(resendConfirmationMailSuccess());
    } catch (e) {
        console.log({e});
        yield put(authError(e.messageCode));
    }
}

function* requestConfirmationCodeSMSSaga() {
    const user = yield select(selectUser);
    const data = yield select(selectFormInputs);
    try {
        if (data.phone && data.phone !== "") {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, '/users', true, {phone: data.phone});
            if (resp.status !== 200) {
                yield call(dispatchErrorAccordingToStatus, resp.status, "error.update.phone", authError)
                return
            } else {
                yield put(updateSingleUserField({field: "phone", value: data.phone}))
            }
        }
        const resp = yield call(getOrPostToAuthenticatedRoute, user, "/tel/update", true);
        if (resp.status === 200) {
            yield put(requestConfirmationCodeSMSSuccess());
        } else if (resp.status === 403 || resp.status === 500) {
            const errorData = yield resp.json();
            yield put(authError(errorData.messageCode !== "" ? errorData.messageCode : "error.sms.send"))
        } else {
            yield put(authError("error.sms.send"));
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.messageCode));
    }
}

function* requestConfirmPhoneSaga(action) {
    const user = yield select(selectUser);
    const data = yield select(selectFormInputs);
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/tel/code?codeSms=${data.verificationCode}`, true);
        if (resp.status === 200) {
            yield put(confirmPhoneSuccess());
            yield put(stepSucceeded());
        } else {
            yield put(authError("error.phone.confirmed"))
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.messageCode));
    }
}

function* saveProfileSaga(action) {
    const user = yield select(selectUser);
    const fingerprint = yield select(selectFingerprint);
    const data = {...yield select(selectFormInputs), fingerprint};
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, '/users', true, data);
        if (resp.status !== 200) {
            yield put(authError(resp.statusText))
        } else {
            // refresh user to make sure their attributes are up to date
            //const newUser = yield call([Auth, Auth.currentAuthenticatedUser], {bypassCache: true});

            yield call(refreshSessionAndSetCookies);
            const accessToken = getOdinCookie("access");
            const user = _parseJwt(accessToken);

            yield put(saveProfileSuccess(user));
            const resp = yield call(getOrPostToAuthenticatedRoute, user, "/users");
            if (resp.status !== 200) {
                yield put(authError(resp.statusText))
            } else {
                const data = yield resp.json();
                yield put(updateUserProfile(data));
            }
            if (action.payload) {
                yield put(nextActiveStep())
            }
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* forgotPasswordSaga(action) {
    const data = yield select(selectFormInputs);

    const hasValidEmail = data.email !== undefined && data.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
    if (!hasValidEmail) {
        yield put(authError("invalid.email"));
        return
    }
    try {
        yield call(forgotPassword, data.email, action.payload.lang);
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* confirmForgotPasswordSaga() {
    const data = yield select(selectFormInputs);
    try {
        yield call(
            resetPassword,
            data.password,
            );
        yield put(confirmForgotPasswordSuccess());
        yield put(push('/login'));
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* changePasswordSaga() {
    const data = yield select(selectFormInputs);
    const access = getOdinCookie("access");
    try {
        yield call(changePassword, data.password, data.newPassword, access);
        yield put(changePasswordSuccess());
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* signOutSaga() {
    try {
        yield put(restoreDefault());
        yield put(resetTunnel());
        const refresh = getOdinCookie("refresh")
        const access = getOdinCookie("access")
        yield call(odinSignOut, refresh, access);
        setOdinCookie("", "access", 0);
        setOdinCookie("", "refresh", 0);
        yield put(push('/login'));
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

export const setOdinCookie = (token, tokenType, expiresUnix) => {
    document.cookie = `${config.cookiePrefix}.${tokenType}=${token}; domain=${config.domain}; expires=${new Date(expiresUnix*1000).toUTCString()}; path=/; SameSite=Lax; Secure`;
};

export const getOdinCookie = (tokenType) => {
    return getCookie(`${config.cookiePrefix}.${tokenType}`);
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

function* continueAsClickSaga() {
    const user = yield select(selectUser);
    const redirUri = yield select(selectRedirUri);
    const initialLocation = yield select(selectInitialLocation);
    try {
        yield call(refreshSessionAndSetCookies);
        if (!isUserValid(user) && !isTunnelingRoute(initialLocation)) {
            yield put(push('/sign-up/confirm'));
        } else if (initialLocation !== defaultLocation) {
            yield put(push(initialLocation));
            yield put(setUserPath({initialLocation: defaultLocation}));
        } else {
            yield put(setLoading());
            yield put(resetUserPath());
            handleRedirect(redirUri)
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

const handleRedirect = (redirUri) => {
    window.location.replace(`http${process.env.NODE_ENV === 'production' ? 's' : ''}://${config.isRecette ? 'recette.' : ''}${config.domain}${redirUri || ''}`);
}

const refreshSessionAndSetCookies = async (redirect = false, redirUri = "") => {
    const refreshTok = getOdinCookie("refresh");
    const resp = await refreshToken(refreshTok)
    setOdinCookie(resp.accessToken.val, "access", resp.accessToken.exp);
    if (redirect) handleRedirect(redirUri)
};

function* getSumSubTokenSaga(action) {
    const user = yield select(selectUser);
    const { level, saleId } = action.payload;
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/sumsub/token?level=${level}${saleId ? "&saleId=" + saleId: ""}`);
        const data = yield resp.json();
        yield put(requestSumSubTokenSuccess(data.token))
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* updateUserApplicantSaga(action) {
    const user = yield select(selectUser);
    try {
        yield call(getOrPostToAuthenticatedRoute, user, '/users', true, {applicantId: action.payload.applicantId});
        // failure is not a major issue
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* updateFingerprintSaga() {
    try {
        let fingerprint = "";
        const auth2Token = document.cookie.split(';').find(c => c.includes(config.uuidCookieName));
        if (auth2Token) {
            fingerprint = auth2Token.split('=')[1];
        }
        yield put(updateFingerprintSuccess(fingerprint));
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* watchAuthSaga() {
    yield takeLatest(signInRequest, signInSaga);
    yield takeLatest(signInWithAppleRequest, signInWithAppleSaga);
    yield takeLatest(signInSuccess, loadProfileSaga);
    yield takeLatest(refreshUserProfile, loadProfileSaga);
    yield takeLatest(signUpRequest, signUpSaga);
    yield takeLatest(confirmSignUpRequest, confirmSignUpSaga);
    yield takeLatest(resendConfirmationMail, resendConfirmationSaga);
    yield throttle(15000, requestConfirmationCodeSMS, requestConfirmationCodeSMSSaga);
    yield takeLatest(confirmPhoneRequest, requestConfirmPhoneSaga);
    yield takeLatest(saveProfileRequest, saveProfileSaga);
    yield takeLatest(forgotPasswordRequest, forgotPasswordSaga);
    yield takeLatest(confirmForgotPasswordRequest, confirmForgotPasswordSaga);
    yield takeLatest(changePasswordRequest, changePasswordSaga);
    yield takeLatest(requestSumSubToken, getSumSubTokenSaga);
    yield takeLatest(updateUserApplicantIdRequest, updateUserApplicantSaga);
    yield takeLatest(continueAsClick, continueAsClickSaga);
    yield takeLatest(signOut, signOutSaga);
    yield takeLatest(REHYDRATE, initSaga);
    yield takeLatest(updateFingerprintRequest, updateFingerprintSaga);
}

export default watchAuthSaga;